import React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"

// Components
import Layout from "../../components/Layout"
import Breadcrumbs from "../../components/Breadcrumbs"
import Product from "../../components/Product"
import SplitCard from "../../components/pages/SplitCard"
import SplitPanel from "../../components/pages/SplitPanel"

//Content
import content from "../../pages/product-cats/dual-function.yaml"

class Index extends React.Component {
  render() {
    const breadcrumbs = [
      {
        label: content.title,
        path: "/dual-function/",
        active: true,
      },
    ]

    const items = []
    const products = this.props.data.allProductsYaml.edges
    products.forEach((product) => {
      items.push(<Product data={product} key={product.node.fields.slug} />)
    })

    return (
      <Layout>
        <Helmet defer={false}>
          <title>{content.seo_title}</title>
          <meta name="description" content={content.seo_description} />
          <meta property="og:image" content={content.seo_image} />
        </Helmet>

        <Breadcrumbs items={breadcrumbs} />

        {/* Feature */}
        <div className="feature-banner uk-section uk-section-secondary uk-padding-remove-top">
          <div className="uk-container">
            <div className="uk-width-2-3@m uk-margin-auto uk-text-center">
              <h1 className="uk-margin-small uk-text-bold">
                {content.description}
              </h1>
              <h2 className="uk-h5 uk-text-large uk-margin-medium uk-margin-remove-top">
                {content.title} products
              </h2>
            </div>
            <div className="uk-width-4-5@l uk-margin-auto uk-text-center">
              <div
                className="uk-grid uk-grid-small uk-child-width-1-1 uk-child-width-1-2@s"
                uk-grid=""
              >
                {items}
              </div>
            </div>
          </div>
        </div>

        {/* Panels */}
        <div className="uk-section">
          <div className="uk-container">
            <SplitCard
              title={content.panel1.title}
              desc={content.panel1.desc}
              link={null}
              btn={null}
              image={content.panel1.image.url}
              align="right"
            />

            <SplitCard
              title={content.panel2.title}
              desc={content.panel2.desc}
              link={null}
              btn={null}
              image={content.panel2.image.url}
              align="left"
            />

            <SplitCard
              title={content.panel3.title}
              desc={content.panel3.desc}
              link={content.panel3.link}
              btn={content.panel3.btnText}
              image={content.panel3.image.url}
              align="right"
              margin={true}
            />

            <SplitPanel
              title={content.cta.title}
              link={content.cta.link}
              btn={content.cta.btnText}
              image={"/images/cta/shop-range_CTA.jpg"}
              color="light"
              align="left"
              fullWidth={false}
            />
          </div>
        </div>
        <div className="uk-section uk-section-small">
          <div className="uk-container">
            <div className="uk-width-3-4 uk-width-2-3@s uk-width-1-2@m">
              <small className="uk-text-meta">{content.seo_description} </small>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Index

export const categoryQuery = graphql`
  query reverseCycleQuery {
    allProductsYaml(
      limit: 4
      filter: { categories: { eq: "dual-function" } }
      sort: { fields: sort, order: ASC }
    ) {
      edges {
        node {
          id
          title
          description_short
          variants {
            price
          }
          featured_image
          fields {
            slug
          }
        }
      }
    }
  }
`
